<template>
  <div>
    <Breadcrumbs />
    <section class="page-not-found py-4">
      <b-container>
        <cmsBlockSimple
          v-if="typeof imageCmsData == 'object'"
          :identifier="imageCmsData.identifier"
        />
      </b-container>
      <div class="top-week">
        <b-container>
          <h2 class="section-title">Of zoek je een van deze producten?</h2>
          <ClientOnly>
            <TopProductsCarousel />
          </ClientOnly>
        </b-container>
      </div>
    </section>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";
import TopProductsCarousel from "@/base/core/components/home/TopProductsCarousel";

export default {
  name: "PageNotFound",
  components: {
    Breadcrumbs,
    cmsBlockSimple,
    TopProductsCarousel,
    ClientOnly,
  },
  computed: {
    sidebarData() {
      return [];
    },
    imageCmsData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "page_not_found"
      );
    },
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "title", content: this.$t("404_line_1") },
        { name: "keywords", content: this.$t("404_line_1") },
        { name: "description", content: this.$t("404_line_1") },
      ],
      title: this.$t("404_line_1"),
    };
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              centerMode: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              autoplay: true,
              speed: 5000,
            },
          },
        ],
      },
      /*
      sidebarData: {
        heading: "Categorie",
        options: [
          { displayName: "Gentlemen", linkTo: "#", active: false },
          { displayName: "Women", linkTo: "#", active: false },
          { displayName: "Bicycle clothing sets", linkTo: "#", active: false },
          { displayName: "Children", linkTo: "#", active: false },
          { displayName: "Cycling shoes", linkTo: "#", active: false },
          { displayName: "For your bike", linkTo: "#", active: false },
          { displayName: "For your body", linkTo: "#", active: false },
          { displayName: "Brands", linkTo: "#", active: false },
          { displayName: "Cycling books", linkTo: "#", active: false },
          { displayName: "Gift voucher", linkTo: "#", active: false },
        ],
      },
      */
    };
  },
  mounted() {
    const bcrumb = { current: "404", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
};
</script>

<style lang="scss" scoped>
@import "@/base/scss/variables.scss";
</style>
